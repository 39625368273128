export const DEFAULT_LANGUAGE = 'en';
export const SUPPORTED_LANGUAGES = {
  en: 'en',
  de: 'de',
  fr: 'fr',
  it: 'it',
  rm: 'rm'
};
export const toSupportedLanguage = (value, defaultLanguage) => {
  const language = SUPPORTED_LANGUAGES[value];
  return language || defaultLanguage;
};