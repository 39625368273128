import { createRequestUrl } from '../../../play-core/utils/request-url-helper';
export const VideoDetailApiPath = '/video';
export const loadVideoDetail = async (axios, urn) => {
  if (urn) {
    const requestUrl = createRequestUrl(VideoDetailApiPath, {
      urn
    });
    const {
      data
    } = await axios.get(requestUrl);
    return data.data;
  }
};