import { useMemo } from 'react';
import { useLatestEpisodesByShow } from '../pages/VideoDetail/use-latest-episodes-by-show';
import { sortByDate } from '../utils/media-helper';
const defaultMedia = [];
export const useLatestVideos = actualMedia => {
  const {
    data: medias = defaultMedia
  } = useLatestEpisodesByShow(actualMedia?.show);

  // make sure there is no reference change in return value if the actualMedia does not change; otherwise it will rerender the player
  return useMemo(() => {
    const latestVideos = medias.sort(sortByDate('desc'));
    const indexOfActual = latestVideos.findIndex(media => media.id === actualMedia?.id);
    if (indexOfActual < 0) {
      return latestVideos;
    }
    return latestVideos.slice(indexOfActual + 1);
  }, [actualMedia?.id, medias]);
};