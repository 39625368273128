import { styled } from 'styled-components';
import { Colors } from '../../../../../play-core/styles/Colors';
import { Fonts } from '../../../../../play-core/styles/Fonts';
import { headingStyle } from '../../../../../play-core/styles/heading-style';
import { Viewports } from '../../../../../play-core/styles/Viewports';
export const StyledVideoDescription = styled.div.withConfig({
  displayName: "VideoDescriptionstyles__StyledVideoDescription",
  componentId: "sc-1aphhe5-0"
})(["width:100%;display:flex;flex-direction:column;gap:16px;", "{padding-right:30px;}"], Viewports.for('largeUp'));
export const Title = styled.h1.withConfig({
  displayName: "VideoDescriptionstyles__Title",
  componentId: "sc-1aphhe5-1"
})(["color:", ";margin:0;", ";"], Colors.WHITE, headingStyle);
export const Lead = styled.p.withConfig({
  displayName: "VideoDescriptionstyles__Lead",
  componentId: "sc-1aphhe5-2"
})(["", ";line-height:28px;margin:0;"], Fonts.regular(18));
export const Description = styled.p.withConfig({
  displayName: "VideoDescriptionstyles__Description",
  componentId: "sc-1aphhe5-3"
})(["", ";line-height:28px;margin:0;max-width:900px;"], Fonts.light(18));
export const ShowMore = styled.button.withConfig({
  displayName: "VideoDescriptionstyles__ShowMore",
  componentId: "sc-1aphhe5-4"
})(["", ";line-height:28px;color:", ";&:hover{color:", ";}"], Fonts.regular(18), Colors.GREY_e1e1e1, Colors.WHITE);
export const Layout = styled.section.withConfig({
  displayName: "VideoDescriptionstyles__Layout",
  componentId: "sc-1aphhe5-5"
})(["display:flex;justify-content:space-between;padding-bottom:", ";", "{flex-direction:column;}"], ({
  extended
}) => extended ? '24px' : '0', Viewports.for('small'));