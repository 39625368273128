import { useQuery } from '@tanstack/react-query';
import { loadLatestEpisodesByShowUrns } from '../../api/load-latest-episodes-by-show-urns';
import { queryKeys } from '../../config/use-query';
import { useAxios } from '../../hooks/use-axios';
const pageSize = 100;
export const useLatestEpisodesByShow = show => {
  const axios = useAxios();
  return useQuery({
    queryKey: [queryKeys.LatestEpisodesByShows(show?.urn, pageSize), axios],
    queryFn: () => loadLatestEpisodesByShowUrns(axios, show?.urn ? [show?.urn] : [], pageSize),
    enabled: !!show?.urn
  });
};