import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { loadTrendingMediaByTopics } from '../../api/topic/load-trending-media-by-topics';
import { queryKeys } from '../../config/use-query';
import { useAxios } from '../../hooks/use-axios';
const pageSize = 40;
const useTrendingMediasByTopics = (topicIds = []) => {
  const axios = useAxios();
  return useQuery({
    queryKey: [queryKeys.TrendingMediaByTopic(topicIds), axios],
    queryFn: () => loadTrendingMediaByTopics(axios, topicIds, ['EPISODE'], pageSize),
    enabled: topicIds.length > 0
  });
};
export const useFallbackPlaylist = media => {
  const topicIds = useMemo(() => media?.topics?.map(topic => topic.id), [media]);
  const {
    data: medias
  } = useTrendingMediasByTopics(topicIds);

  /*
   make sure there is no reference change in return value if the actualMedia does not change; otherwise it will rerender the player
  */
  return useMemo(() => medias?.data.filter(current => current.id !== media?.id) ?? [], [media?.id, medias?.data]);
};