import { styled } from 'styled-components';
import { Colors } from '../../../../../play-core/styles/Colors';
import { Fonts } from '../../../../../play-core/styles/Fonts';
import { Viewports } from '../../../../../play-core/styles/Viewports';
export const MetaInformationContainer = styled.div.withConfig({
  displayName: "MetaInformationstyles__MetaInformationContainer",
  componentId: "sc-ty41f-0"
})(["display:flex;flex-wrap:wrap;align-items:center;", "{flex-direction:column;align-items:flex-start;}gap:8px;", ";color:", ";"], Viewports.for('small'), Fonts.regular(16), Colors.GREY_d2d2d2);
export const ContentContainer = styled.div.withConfig({
  displayName: "MetaInformationstyles__ContentContainer",
  componentId: "sc-ty41f-1"
})(["display:flex;flex-wrap:wrap;align-items:center;gap:8px;"]);
export const InfoTextContainer = styled.div.withConfig({
  displayName: "MetaInformationstyles__InfoTextContainer",
  componentId: "sc-ty41f-2"
})(["display:flex;flex-wrap:wrap;"]);