import { createRequestUrl } from '../../../../play-core/utils/request-url-helper';
export const TrendingMediaByTopicsApiPath = '/trending-media-by-topics';
export const loadTrendingMediaByTopics = async (axios, topicIds, types, pageSize, next) => {
  const requestUrl = createRequestUrl(TrendingMediaByTopicsApiPath, {
    topicIds,
    types,
    pageSize: next ? undefined : pageSize,
    next
  });
  const {
    data
  } = await axios.get(requestUrl);
  return data.data;
};