import { useRef, useState } from 'react';
export const usePlayerControlsVisible = () => {
  const isPlayingRef = useRef(false);
  const [controlsVisible, setControlsVisible] = useState(true);
  return [controlsVisible, {
    onPause: () => {
      isPlayingRef.current = false;
    },
    onPlay: player => {
      isPlayingRef.current = true;
      // TODO: Is "userActive_" a typo? Should it be "userActive"?
      // eslint-disable-next-line no-underscore-dangle
      if (!player?.player?.userActive_) {
        setControlsVisible(false);
      }
    },
    onInactive: () => {
      if (isPlayingRef.current) {
        setControlsVisible(false);
      }
    },
    onActive: () => {
      setControlsVisible(true);
    }
  }];
};