import { styled } from 'styled-components';
import { Colors } from '../../../../../play-core/styles/Colors';
import { Fonts } from '../../../../../play-core/styles/Fonts';
import { Viewports } from '../../../../../play-core/styles/Viewports';
import { PlaySecondaryLink } from '../../../components/ClickableComponents/PlayLink';
export const Links = styled.div.withConfig({
  displayName: "EpisodeLinksstyles__Links",
  componentId: "sc-pip391-0"
})(["flex-direction:column;width:100%;display:flex;gap:8px;"]);
export const LinkContainer = styled.div.withConfig({
  displayName: "EpisodeLinksstyles__LinkContainer",
  componentId: "sc-pip391-1"
})(["width:100%;margin-bottom:24px;"]);
export const Heading = styled.h2.withConfig({
  displayName: "EpisodeLinksstyles__Heading",
  componentId: "sc-pip391-2"
})(["line-height:48px;", ";color:", ";border-bottom:1px solid ", ";margin:0;"], Fonts.regular(16), Colors.GREY_969696, Colors.GREY_2a2a2a);
export const StyledLink = styled(PlaySecondaryLink).withConfig({
  shouldForwardProp: () => true
}).withConfig({
  displayName: "EpisodeLinksstyles__StyledLink",
  componentId: "sc-pip391-3"
})(["width:fit-content;", "{width:100%;}"], Viewports.for('small'));